// FeedbackPage.tsx
import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { debounce } from "lodash";
import { useSearchParams, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../configs/firebase";
import { fetchUserData } from "../../helpers";
import FeatureModal from "../../components/Modal";

const allQuestions: string[] = [
  "This person is my friend",
  "This person is the life of the party",
  "Can make others laugh and improve their mood",
  "Can always ask this person for help",
  "Is the initiator of crazy parties",
  "Knows how to make friends",
  "I would like more than just friendship with this person",
  "Could be a role model for many",
  "Loves to dress up",
  "Speaks in a loud voice",
  "Always looks great",
  "Loves animals (and children)",
];

const groupedQuestions: string[][] = [
  allQuestions.slice(0, 3),
  allQuestions.slice(3, 6),
  allQuestions.slice(6, 9),
  allQuestions.slice(9, 12),
];

type QuestionKeys =
  | "question1"
  | "question2"
  | "question3"
  | "question4"
  | "question5"
  | "question6"
  | "question7"
  | "question8"
  | "question9"
  | "question10"
  | "question11"
  | "question12";

const questionKeys = [
  "question1",
  "question2",
  "question3",
  "question4",
  "question5",
  "question6",
  "question7",
  "question8",
  "question9",
  "question10",
  "question11",
  "question12",
];

interface AnswersState {
  [key: string]: string;
}

const initialAnswers: AnswersState = {
  question1: "",
  question2: "",
  question3: "",
  question4: "",
  question5: "",
  question6: "",
  question7: "",
  question8: "",
  question9: "",
  question10: "",
  question11: "",
  question12: "",
};

export interface UserData {
  count: number;
  created_time: string;
  display_name?: string;
  email: string;
  image: string;
  photo_url?: string;
  uid: string;
  description?: string;
}

const FeedbackPage: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isNarrowScreen = useMediaQuery("(max-width: 400px)");

  const sliderRef = useRef<any>(null);

  // states
  const [answers, setAnswers] = useState<AnswersState>(initialAnswers);
  const [activeStep, setActiveStep] = useState(0);
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("frn");
  const [user, setUser] = useState<UserData | null>(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [open, setOpen] = useState(false);
  const [documentId, setDocumentId] = useState<string | null>(null);
  const [selectFriendsCounter, setSelectFriendsCounter] = useState<number>(0);

  // get user by id
  useEffect(() => {
    if (userId) {
      fetchUserData(userId, setUser);
    }
  }, [db, userId]);

  const calculateFilledQuestions = (answers: Partial<AnswersState>) => {
    const questionKeys = Object.keys(answers).filter((key) =>
      key.startsWith("question")
    );

    const filledCount = questionKeys.reduce((count, key) => {
      if (answers[key] !== "") {
        return count + 1;
      }
      return count;
    }, 0);

    return filledCount;
  };

  const updateFirebaseDoc = async (
    answersState: AnswersState,
    lastStep: boolean = false
  ) => {
    try {
      const filteredAnswers = Object.fromEntries(
        Object.entries(answersState).filter(([key]) => !key.startsWith("slide"))
      );
      const filledCount = calculateFilledQuestions(filteredAnswers);

      const docData = {
        ...filteredAnswers,
        filled: `${filledCount}/12`,
        user: userId,
      };

      if (documentId) {
        const docRef = doc(db, "questions", documentId);
        await setDoc(docRef, docData, { merge: true });
      } else if (!documentId) {
        const docRef = await addDoc(collection(db, "questions"), docData); // Create doc
        setDocumentId(docRef.id); // Save doc's id
      }
      if (lastStep) {
        setIsSubmitted(true);
        navigate("/feedback?successful", { replace: true });
      }
    } catch (error) {
      console.error("Error while updating:", error);
    }
  };

  const markSelectFriendsClicked = async () => {
    if (documentId) {
      const docRef = doc(db, "questions", documentId);
      try {
        await updateDoc(docRef, {
          "select friends button clicked": selectFriendsCounter + 1,
        });
        setSelectFriendsCounter(selectFriendsCounter + 1);
        console.log("Document updated with 'select friends button clicked'");
      } catch (error) {
        console.error("Error updating document:", error);
      }
    } else {
      console.error("No document ID found");
    }
  };

  const debouncedUpdate = useCallback(
    debounce((answersState, lastStep) => {
      updateFirebaseDoc(answersState, lastStep);
    }, 1000),
    [documentId]
  );

  const handleNext = () => {
    if (activeStep < groupedQuestions.length - 1) {
      sliderRef.current.slickNext();
      setActiveStep((prev) => prev + 1);
    } else {
      debouncedUpdate(answers, true);
    }
  };
  const handleBack = () => {
    if (sliderRef.current) sliderRef.current.slickPrev();
  };

  const handleSelectFriends = () => {
    setOpen(true);
    markSelectFriendsClicked();
  };

  const handleClose = () => {
    setOpen(false); // close alert
  };

  const handleRadioChange = (questionKey: string, value: string) => {
    setAnswers((prevAnswers) => {
      const newAnswers = { ...prevAnswers, [questionKey]: value };
      debouncedUpdate(newAnswers, false);
      return newAnswers;
    });
  };

  // create document when open the page
  useEffect(() => {
    if (user && !documentId) {
      debouncedUpdate(answers, false);
    }
  }, [user]);

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    beforeChange: (current: number, next: number) => setActiveStep(next),
    appendDots: (dots: React.ReactNode) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "auto",
        }}
      >
        <ul
          style={{
            margin: "0px",
            display: "flex",
            gap: "8px",
            alignItems: "center",
          }}
        >
          {dots}
        </ul>
      </div>
    ),
    customPaging: (i: number) => (
      <div
        style={{
          width: activeStep === i ? "40px" : "25px",
          height: "8px",
          borderRadius: "5px",
          backgroundColor: activeStep === i ? "#2979ff" : "#B0BEC5",
          transition: "width 0.3s, height 0.3s",
          transform: "translateX(-50%)",
        }}
      />
    ),
  };

  const renderQuestionSlide = (questions: string[], slideIndex: number) => {
    return (
      <FormControl component="fieldset">
        {questions.map((question, index) => {
          const questionKey = questionKeys[slideIndex * 3 + index];

          return (
            <Box
              mb={2}
              key={questionKey}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderBottom: "1px solid #ECECEC",
              }}
            >
              <Typography
                align="left"
                sx={{
                  width: isMobile ? "50%" : "55%",
                  color: "#7B8189",
                  fontSize: isNarrowScreen ? 12 : "default",
                }}
              >
                {question}
              </Typography>
              <RadioGroup
                row
                value={answers[questionKey] || null}
                onChange={(e) =>
                  handleRadioChange(questionKey as QuestionKeys, e.target.value)
                }
                sx={{ color: "#7B8189" }}
              >
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: isNarrowScreen ? "12px" : "16px",
                    },
                  }}
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: isNarrowScreen ? "12px" : "16px",
                    },
                  }}
                  value="No"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </Box>
          );
        })}
      </FormControl>
    );
  };

  return (
    <Container maxWidth="sm">
      <Box textAlign="center" my={4}>
        <img
          src={"/LOGO.png"}
          alt="profile"
          style={{
            width: "56px",
            height: "59px",
            objectFit: "contain",
          }}
        />
        <Typography fontWeight={600}>
          Social network for friends’ reviews
        </Typography>
        <Typography fontSize={14}>
          Leave a review to help me find new connections and old friends. Come
          up with something nice.
        </Typography>
        {!isSubmitted ? (
          <>
            <Box mt={2} mb={4}>
              <Box
                sx={{
                  width: "74px",
                  height: "74px",
                  borderRadius: "50%",
                  backgroundColor: "#CFE3D7",
                  margin: "auto",
                  overflow: "hidden",
                  padding: 1,
                }}
              >
                {user?.image ? (
                  <img
                    src={user.image}
                    alt="profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <Typography color="textSecondary">No Photo</Typography>
                )}
              </Box>
              <Typography
                variant="h5"
                mt={2}
                sx={{ fontSize: 24, fontWeight: 600, color: "#354050" }}
              >
                {user?.display_name}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#354050", fontSize: 21 }}
              >
                {user?.description ||
                  "Please let me know what do you think about me"}
              </Typography>
            </Box>
            <Slider ref={sliderRef} {...sliderSettings}>
              {groupedQuestions.map((questions, stepIndex) => {
                return (
                  <Card
                    key={stepIndex}
                    sx={{
                      minHeight: isNarrowScreen
                        ? "300px"
                        : isMobile
                        ? "360px"
                        : "320px",
                      position: "relative",
                      boxShadow: "2px 4px 25px 0px #D2EAD4",
                      maxWidth: isNarrowScreen
                        ? "330px"
                        : isMobile
                        ? "380px"
                        : "370px",
                      mt: 1,
                      borderRadius: "4px",
                    }}
                  >
                    <CardContent
                      sx={{ paddingBottom: isNarrowScreen ? "50px" : "80px" }}
                    >
                      <>{renderQuestionSlide(questions, stepIndex)}</>
                    </CardContent>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "16px",
                        left: "16px",
                        right: "16px",
                        display: "flex",
                        justifyContent: "space-between",
                        height: "56px",
                      }}
                    >
                      <Button
                        variant="contained"
                        onClick={handleBack}
                        sx={{
                          visibility: stepIndex === 0 ? "hidden" : "visible",
                          pointerEvents: activeStep === 0 ? "none" : "default",
                          backgroundColor: "#037FF2",
                          width: "140px",
                        }}
                      >
                        <Icon icon={"tabler:arrow-left"} fontSize={18} />
                        <Box
                          component={"span"}
                          sx={{
                            fontWeight: 600,
                            textTransform: "none",
                            fontSize: 18,
                          }}
                        >
                          Back
                        </Box>
                      </Button>

                      <Button
                        variant={"contained"}
                        onClick={handleNext}
                        sx={{
                          backgroundColor: "#037FF2",
                          width: "140px",
                        }}
                      >
                        <Box
                          component={"span"}
                          sx={{
                            fontWeight: 600,
                            textTransform: "none",
                            fontSize: 18,
                          }}
                        >
                          {stepIndex === groupedQuestions.length - 1
                            ? "Submit"
                            : "Next"}
                        </Box>
                        {stepIndex !== groupedQuestions.length - 1 && (
                          <Icon icon={"tabler:arrow-right"} fontSize={18} />
                        )}
                      </Button>
                    </Box>
                  </Card>
                );
              })}
            </Slider>
          </>
        ) : (
          <>
            <Box mt={2} mb={4}>
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "50%",
                  backgroundColor: "#CFE3D7",
                  margin: "auto",
                  overflow: "hidden",
                }}
              >
                <img
                  src={"/ava.png"}
                  alt="profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Card
                sx={{
                  minHeight: isMobile ? "390px" : "330px",
                  position: "relative",
                  boxShadow: "2px 4px 25px 0px #D2EAD4",
                  width: isNarrowScreen
                    ? "330px"
                    : isMobile
                    ? "380px"
                    : "370px",
                  mt: 1,
                  borderRadius: "4px",
                  pt: 1,
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#51B793", fontWeight: 600, mb: 2 }}
                >
                  Thanks for answers!
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    width: "96%",
                    textAlign: "center",
                    margin: "auto",
                    fontSize: 16,
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  We will post your profile information after a short review
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "96%",
                    textAlign: "center",
                    margin: "auto",
                    fontSize: 19,
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Help your friends by inviting them so they can find new
                  friends or the love of life in the company of friends
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "95%",
                    textAlign: "center",
                    margin: "auto",
                    fontSize: 12,
                    color: "#354050",
                  }}
                >
                  We will request access to your smartphone's phone book to show
                  a list of people you can invite
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "8px",
                    left: "16px",
                    right: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "56px",
                  }}
                >
                  <Button
                    variant={"contained"}
                    onClick={handleSelectFriends}
                    sx={{
                      backgroundColor: "#037FF2",
                      width: "100%",
                    }}
                  >
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: 600,
                        textTransform: "none",
                        fontSize: 18,
                      }}
                    >
                      Select Friends
                    </Box>
                  </Button>
                </Box>
              </Card>
            </Box>
          </>
        )}
        <FeatureModal open={open} handleClose={handleClose} />
      </Box>
    </Container>
  );
};

export default FeedbackPage;
