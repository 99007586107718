import { Alert, Box, Button, Modal } from "@mui/material";
import React, { SyntheticEvent } from "react";

type ModalProps = {
  open: boolean;
  handleClose: (event: SyntheticEvent<Element, Event>) => void;
};

const FeatureModal: React.FC<ModalProps> = ({ open, handleClose }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="centered-alert-modal"
      aria-describedby="feature-coming-soon"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          maxWidth: 400,
          bgcolor: "background.paper",
          borderRadius: "8px",
          boxShadow: 24,
          p: 1,
        }}
      >
        <Alert
          onClose={handleClose}
          severity="info"
          action={
            <Button color="primary" onClick={handleClose} size="small">
              OK
            </Button>
          }
        >
          This feature will be added soon
        </Alert>
      </Box>
    </Modal>
  );
};

export default FeatureModal;
