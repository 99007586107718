import { initializeApp } from "firebase/app";
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPk-VhPF1Y_uN41NvdwMt9JK303ZQFhEU",
  // authDomain: "friendz-f6df6.firebaseapp.com",
  projectId: "friendz-f6df6",
  storageBucket: "friendz-f6df6.appspot.com",
  messagingSenderId: "259067624071",
  appId: "1:259067624071:web:72b9e13eb37a87fff19622",
  // measurementId: "G-5ZXWEZBRQG"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, app, analytics };