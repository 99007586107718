import { doc, getDoc } from "firebase/firestore";
import { UserData } from "../pages/Feedback/Feedback";
import { db } from "../configs/firebase";

export const fetchUserData = async (id: string, setUser: (user: UserData) => void) => {
  const docRef = doc(db, "users", id);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    setUser(docSnap.data() as UserData);
  } else {
    console.log("No such document!");
  }
};


