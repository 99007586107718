import { Icon } from "@iconify/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserData } from "../Feedback/Feedback";
import { db } from "../../configs/firebase";
import { fetchUserData } from "../../helpers";
import { addDoc, collection } from "firebase/firestore";
import FeatureModal from "../../components/Modal";

const initialAnswers = {
  comment: "",
};

const CommentPage: React.FC = () => {
  const [answers, setAnswers] = useState(initialAnswers);
  const [open, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [user, setUser] = useState<UserData | null>(null);

  // hooks
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("frn");
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isNarrowScreen = useMediaQuery("(max-width: 400px)");

  useEffect(() => {
    if (userId) {
      fetchUserData(userId, setUser);
    }
  }, [db, userId]);

  const handleSelectFriends = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false); // close alert
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newComment = event.target.value;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      comment: newComment,
    }));
  };

  const handleSubmitComment = async () => {
    try {
      await addDoc(collection(db, "comments"), {
        ...answers,
        user: userId,
      });

      setIsSubmitted(true);
      navigate("/comment?successful", { replace: true });
    } catch (error) {
      console.error("Error submitting answers:", error);
    }
  };

  return (
    <Container maxWidth={"sm"}>
      <Box textAlign="center" my={4}>
        <img
          src={"/LOGO.png"}
          alt="profile"
          style={{
            width: "56px",
            height: "59px",
            objectFit: "contain",
          }}
        />
        <Typography fontWeight={600}>
          Social network for friends’ reviews
        </Typography>
        <Typography fontSize={14}>
          Leave a review to help me find new connections and old friends. Come
          up with something nice.
        </Typography>
        {!isSubmitted ? (
          <>
            <Box mt={2} mb={4}>
              <Box
                sx={{
                  width: "74px",
                  height: "74px",
                  borderRadius: "50%",
                  backgroundColor: "#CFE3D7",
                  margin: "auto",
                  overflow: "hidden",
                  padding: 1,
                }}
              >
                {user?.image ? (
                  <img
                    src={user.image}
                    alt="profile"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <Typography color="textSecondary">No Photo</Typography>
                )}
              </Box>
              <Typography
                variant="h5"
                mt={2}
                sx={{ fontSize: 24, fontWeight: 600, color: "#354050" }}
              >
                {user?.display_name}
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#354050", fontSize: 21 }}
              >
                {user?.description ||
                  "Please let me know what do you think about me"}
              </Typography>
            </Box>
            <Card
              sx={{
                minHeight: isNarrowScreen
                  ? "300px"
                  : isMobile
                  ? "360px"
                  : "320px",
                position: "relative",
                boxShadow: "2px 4px 25px 0px #D2EAD4",
                maxWidth: isNarrowScreen
                  ? "330px"
                  : isMobile
                  ? "380px"
                  : "370px",
                mt: 1,
                borderRadius: "4px",
                margin: "auto",
              }}
            >
              <CardContent
                sx={{ paddingBottom: isNarrowScreen ? "50px" : "80px" }}
              >
                <TextField
                  multiline
                  rows={isNarrowScreen ? 6 : 8}
                  label="Add comment"
                  value={answers.comment || ""}
                  onChange={handleCommentChange}
                  fullWidth
                  variant="outlined"
                  margin="normal"
                />
              </CardContent>
              <Box
                sx={{
                  position: "absolute",
                  bottom: "16px",
                  left: "16px",
                  right: "16px",
                  display: "flex",
                  justifyContent: "end",
                  height: "56px",
                }}
              >
                <Button
                  variant={"contained"}
                  onClick={handleSubmitComment}
                  disabled={answers.comment === ""}
                  sx={{
                    backgroundColor: "#037FF2",
                    width: "140px",
                  }}
                >
                  <Box
                    component={"span"}
                    sx={{
                      fontWeight: 600,
                      textTransform: "none",
                      fontSize: 18,
                    }}
                  >
                    {"Submit"}
                  </Box>
                  <Icon icon={"tabler:arrow-right"} fontSize={18} />
                </Button>
              </Box>
            </Card>
          </>
        ) : (
          <>
            <Box mt={2} mb={4}>
              <Box
                sx={{
                  width: "70px",
                  height: "70px",
                  borderRadius: "50%",
                  backgroundColor: "#CFE3D7",
                  margin: "auto",
                  overflow: "hidden",
                }}
              >
                <img
                  src={"/ava.png"}
                  alt="profile"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Card
                sx={{
                  minHeight: isMobile ? "390px" : "330px",
                  position: "relative",
                  boxShadow: "2px 4px 25px 0px #D2EAD4",
                  width: isNarrowScreen
                    ? "330px"
                    : isMobile
                    ? "380px"
                    : "370px",
                  mt: 1,
                  borderRadius: "4px",
                  pt: 1,
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ color: "#51B793", fontWeight: 600, mb: 2 }}
                >
                  Thanks for answers!
                </Typography>
                <Typography
                  variant="h6"
                  sx={{
                    width: "96%",
                    textAlign: "center",
                    margin: "auto",
                    fontSize: 16,
                    fontWeight: 600,
                    mb: 2,
                  }}
                >
                  We will post your profile information after a short review
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "96%",
                    textAlign: "center",
                    margin: "auto",
                    fontSize: 19,
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  Help your friends by inviting them so they can find new
                  friends or the love of life in the company of friends
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "95%",
                    textAlign: "center",
                    margin: "auto",
                    fontSize: 12,
                    color: "#354050",
                  }}
                >
                  We will request access to your smartphone's phone book to show
                  a list of people you can invite
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "8px",
                    left: "16px",
                    right: "16px",
                    display: "flex",
                    justifyContent: "space-between",
                    height: "56px",
                  }}
                >
                  <Button
                    variant={"contained"}
                    onClick={handleSelectFriends}
                    sx={{
                      backgroundColor: "#037FF2",
                      width: "100%",
                    }}
                  >
                    <Box
                      component={"span"}
                      sx={{
                        fontWeight: 600,
                        textTransform: "none",
                        fontSize: 18,
                      }}
                    >
                      Select Friends
                    </Box>
                  </Button>
                </Box>
              </Card>
            </Box>
          </>
        )}
      </Box>
      <FeatureModal open={open} handleClose={handleClose} />
    </Container>
  );
};

export default CommentPage;
