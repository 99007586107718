import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import FeedbackPage from "./pages/Feedback/Feedback";
import CommentPage from "./pages/Comment/Comment";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/feedback" element={<FeedbackPage />} />
        <Route path="/comment" element={<CommentPage />} />
        <Route path="*" element={<Navigate to="/feedback" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
